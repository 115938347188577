

































import { Vue, Prop, Component } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

interface Item {
  name: string;
  img: string;
  facebook: string;
}

@Component
export default class Restaurants extends Vue {
  @Prop({ type: Array, required: true }) list!: Item[];

  get isH5() {
    return AppModule.isH5
  }

}
