





































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

@Component
export default class RestaurantCard extends Vue {
  @Prop(String)
  title: string

  @Prop(String)
  desc: string

  @Prop(String)
  img: string

  @Prop(String)
  h5Img: string

  @Prop(String)
  link: string

  @Prop(String)
  date: string

  get isH5() {
    return AppModule.isH5
  }
}
