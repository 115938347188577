









































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

@Component
export default class ProgramCard extends Vue {
  @Prop(String)
  name: string

  @Prop(String)
  enName: string

  @Prop(Array)
  desc: string[]

  @Prop(String)
  img: string

  @Prop(String)
  link: string

  get isH5() {
    return AppModule.isH5
  }
}
