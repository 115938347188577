








































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

import ProgramCard from './components/ProgramCard.vue'
import ProgramDemoCard from './components/ProgramDemoCard.vue'
import ServiceCard from './components/ServiceCard.vue'
import BannerSlides from './components/BannerSlides.vue'
import RestaurantCard from './components/RestaurantCard.vue'
import ContactBlock from '@/components/ContactBlock.vue'
import FooterBlock from '@/components/FooterBlock.vue'
import PlayBtn from './components/PlayBtn.vue'
import VideoPlayer from './components/VideoPlayer.vue'
import Features from './components/Features.vue'
import Reports from './components/Reports.vue'
import HelpBlock from '@/components/HelpBlock.vue'
import Restaurants from './components/Restaurants.vue'
import BusinessMerchants from './components/BusinessMerchants.vue'

import { scrollToElem } from '@/utils/scroll'
import merchants from './merchants'

@Component({
  components: {
    ProgramCard,
    ProgramDemoCard,
    ServiceCard,
    ContactBlock,
    FooterBlock,
    BannerSlides,
    RestaurantCard,
    PlayBtn,
    VideoPlayer,
    Features,
    Reports,
    HelpBlock,
    Restaurants,
    BusinessMerchants,
  },
})
export default class Home extends Vue {
  isShowVideo = false

  restaurants = [
    /*
    {
      storeName: '春禧堂',
      name: 'Tommy 春禧堂經理',
      img: 'https://spics.wantu.cn/40c30ddad4fe7d75b3fd443e8ab519ce.png',
      comment: 'QR Code落單，<br>令到客人方便落單，<br>營運上更順暢。'
    },
    {
      storeName: '巴辣仔',
      name: '周生 巴辣仔經理',
      img: 'https://spics.wantu.cn/6cca60245e92a081168d3f912a2d51d6.png',
      comment: 'Pin Me的會員系統，<br>幫我們餐廳營運了更多的忠誠客戶，<br>令我們的生意更上一層樓。'
    },
    {
      storeName: '一仔便當',
      name: 'Wing 1仔便當老闆',
      img: 'https://spics.wantu.cn/5003c50766adb69f63a4747dc7f61c12.png',
      comment: '我們這些中小餐廳，<br>實在沒太多資源花在 POS上，<br>Pin me能夠很好滿足我們所需。'
    },
    {
      storeName: '一仔便當',
      name: 'Wing 1仔便當老闆',
      img: 'https://spics.wantu.cn/5003c50766adb69f63a4747dc7f61c12.png',
      comment: '我們這些中小餐廳，<br>實在沒太多資源花在 POS上，<br>Pin me能夠很好滿足我們所需。'
    }
    */
    {
      name: 'Share Tea',
      img: 'https://spics.inctrip.com/b07d3b15a8f7b38eaf07f17723310e3d.png',
      facebook: 'https://www.facebook.com/shareteaHK'
    },
    {
      name: 'Comebuy Tea',
      img: 'https://spics.inctrip.com/6865393854ac565249f780956cce1d79.png',
      facebook: 'https://www.facebook.com/comebuytea'
    },
    {
      name: '遇見小面',
      img: 'https://spics.inctrip.com/56b1197cef22123aeb86de386d3ff8e1.png',
      facebook: 'https://www.facebook.com/profile.php?id=61558260402187'
    },
    {
      name: '燉奶佬餐廳',
      img: 'https://spics.inctrip.com/5f46223b7ededc19d0af125555cfece7.png',
      facebook: 'https://www.facebook.com/DanielsGroupHK'
    },
    {
      name: '那個',
      img: 'https://spics.inctrip.com/f4ec1a25eac7da9a530a53a0a748d1dd.png',
      facebook: 'https://www.facebook.com/NAGETWFOOD'
    },
    {
      name: '十大碗',
      img: 'https://spics.inctrip.com/64cc2f6c09f73629609aa495eb489623.png',
      facebook: 'https://www.facebook.com/tennoodleshop'
    },
    {
      name: '華記車仔面',
      img: 'https://spics.inctrip.com/2e5d5e3b9db10dbf4a944243006115bb.png',
      facebook: 'https://www.facebook.com/pages/%E8%8F%AF%E8%A8%98%E6%BD%AE%E6%B4%B2%E9%B9%B5%E6%B0%B4%E8%BB%8A%E4%BB%94%E9%BA%B5/106434807969200'
    },
    {
      name: 'Peek a poke',
      img: 'https://spics.inctrip.com/83f360f3126ae99e327e6729a1b85144.png',
      facebook: 'https://www.facebook.com/peekapoke'
    },
    {
      name: '馬拉公公',
      img: 'https://spics.inctrip.com/c5ce218b5180d295839b2b1acaf5a876.png',
      facebook: 'https://www.facebook.com/malagrandpa'
    }
  ]

  banners = [
    {
      title: 'PinMe智能「餐廳Pos」',
      name: '對接香港主流外賣平台',
      desc: '一部Pos，同時處理多家外賣訂單',
      imgUrl: 'https://spics.inctrip.com/4cee6d73bf4506ca307ee1dcd5033b1b.png'
    },
    {
      title: 'PinMe智能「餐廳Pos」',
      name: '更齊全online支付方式',
      desc: '帶來更多外賣&自取訂單',
      imgUrl: 'https://spics.inctrip.com/fbcc4b14cf7aab62b1a3f0e7549f027f.png'
    },
    {
      name: 'Pin Me雲端「餐廳POS」<br>幫餐廳做更多生意',
      imgUrl: 'https://spics.wantu.cn/ddf41f44bb1fbdb578d6271824fb13c1.png'
    },
    {
      name: 'Pin Me掃碼落單<br>幫你節省30%人力成本',
      imgUrl: 'https://spics.wantu.cn/922af80d805c8240b22afc76ea2d87e0.png',
      color: '#fff'
    },
    {
      name: '基於「數據」的智能分析',
      imgUrl: 'https://spics.wantu.cn/090d4d853b223622d5f8ab004424b443.png',
      color: '#fff',
      desc: '留住熟客，提高營業額，so easy'
    }
  ]

  programList = [{
    name: '咖啡/飲品/快餐店',
    enName: 'e-Restaurant Lite',
    desc: [
      '電子菜單、掃碼落單',
      '支持會員、印花、積分',
      '堂食、自取、外送'
    ],
    img: 'https://spics.wantu.cn/c974d399ba9ee1ded54c554459f967c4.png',
    link: '/program/snacks'
  }, {
    name: '茶餐廳/冰室/西餐/酒樓',
    enName: 'e-Restaurant Standard',
    desc: [
      '100%支持複雜餐牌',
      '100%貼合餐廳營運習慣',
      '支持會員、印花、積分',
      '堂食、自取、外送'
    ],
    img: 'https://spics.wantu.cn/15eeabae24128b71847db9aedfeaa5d4.png',
    link: '/program/restaurant'
  }, {
    name: '沙律/米線/DIY餐牌',
    enName: 'e-Restaurant Pro',
    desc: [
      '獨家支持客户DIY餐牌',
      '米線、沙律等配料自由選',
      '支持會員、印花、積分',
      '堂食、自取、外送'
    ],
    img: 'https://spics.wantu.cn/8857c863cd75411b52127118882f957f.png',
    link: '/program/pasta'
  }, {
    name: 'Foodcourt 美食廣場',
    enName: 'e-Foodcourt',
    desc: [
      '多檔口電子菜單',
      '掃碼落單、支持外帶、外送',
      '分檔口數據統計'
    ],
    img: 'https://spics.wantu.cn/17edb08f6b9440da5b8308835db416e0.png',
    link: '/program/foodcourt'
  }, {
    name: '中央廚房/虛擬餐廳',
    enName: 'Virtual Kitchen',
    desc: [
      '遠程落單、零租金成本',
      '24小時接單',
      '20多種營銷模式',
      '堂食、自取、外送'
    ],
    img: 'https://spics.wantu.cn/d169e8622fdaa9f95c5155e67859bb04.png',
    link: '/program/takeaway'
  }]

  // 功能模块
  moduleList = [{
    name: '電子餐牌',
  }, {
    name: '收銀Pos',
  }, {
    name: '營銷功能',
  }, {
    name: '手機點餐',
  }, {
    name: '餐廳管理',
  }, {
    name: '雲端分析',
  }, {
    name: '後廚管理',
  }, {
    name: '會員CRM',
  }]

  business_merchant_list = merchants

  get isH5() {
    return AppModule.isH5
  }

  /** 滚动到联系我们 */
  gotoContact() {
    scrollToElem('#contact', 500, 80)
  }

}
