























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'
import Swiper, { Pagination, Autoplay }  from 'swiper'
import 'swiper/swiper-bundle.css';

Swiper.use([Pagination, Autoplay])

@Component
export default class BannerSlides extends Vue {
  bannerIndex = 0

  @Prop(Array)
  banners: string[]

  get isH5() {
    return AppModule.isH5
  }

  mounted() {
    this.$nextTick(() => {
      const bannersSwiper = new Swiper('.banner-slides', {
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      })
      bannersSwiper.on('slideChange', this.changeBannerIndex)
    })
  }

  changeBannerIndex(swiper: Swiper) {
    this.bannerIndex = swiper.activeIndex
  }
}
