

























import { Component, Prop, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

@Component
export default class ServiceCard extends Vue {
  @Prop(String)
  icon: string

  @Prop(String)
  title: string

  @Prop(String)
  desc: string

  @Prop({
    default: 'center',
    type: String
  })
  descTextAlign: string

  get isH5() {
    return AppModule.isH5
  }
}
