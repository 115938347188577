







































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'
import ReportCard from './ReportCard.vue'

@Component({
  components: {
    ReportCard
  }
})
export default class Reports extends Vue {
  reports = [
    {
      img: 'https://spics.wantu.cn/d401131b3b15173d7fa6289c747aacf7.png',
      h5Img: 'https://spics.wantu.cn/8b1e5c685665bb9f57493a054b9b011b.png',
      title: '電話自助落單Pin Me    從用戶體驗行先',
      desc: '食客走進餐廳，拿出智能電話掃描桌上的 Pin Me 二維條碼，開啟瀏覽器便可進入餐牌面頁自助選餐，當中也有自訂選項，如少飯、飲品少冰、少甜等滿足不同食客的口味。三、四個步驟即可完成點餐，過程毋須伙記招待',
      date: '2019-06-05',
      link: 'https://www.pcmarket.com.hk/%E9%9B%BB%E8%A9%B1%E8%87%AA%E5%8A%A9%E8%90%BD%E5%96%AEpin-me%E3%80%80%E5%BE%9E%E7%94%A8%E6%88%B6%E9%AB%94%E9%A9%97%E8%A1%8C%E5%85%88/'
    },
    {
      img: 'https://spics.wantu.cn/460f28550dc1d7059012feb2e784403f.png',
      h5Img: 'https://spics.wantu.cn/d2adcde8857ff9120e5e96c5d09f2a71.png',
      title: 'PinMe: Cloud Infrastructure Empowers e-Restaurant',
      desc: 'As business continues to grow, PinMe will continue to implement more networking, database, and security solutions from Alibaba Cloud. In particular, as the scale of PinMe’s business continues to rise, PinMe intends',
      date: '2019-06-05',
      link: 'https://www.alibabacloud.com/zh/customers/pin-me'
    },
    {
      img: 'https://spics.wantu.cn/9d5c87db69a8011d890ff43296bd0fbe.png',
      h5Img: 'https://spics.wantu.cn/d0d143bf97428874e30680297681a667.png',
      title: '「PinMe」自助點餐系統登場 預計60間餐廳採用',
      desc: '「唔該，落單！」 香港人到餐廳都習慣叫侍應過來點餐，有本港科技公司為中小型食肆研發出餐廳二維碼點餐系統「Pin Me」，不需手機應用程式，客人用手機輕輕一「掃」二維碼牌，即會顯示餐牌並可以自助點餐。對客人而言，不用等候侍應',
      date: '2019-06-05',
      link: 'https://www.upower.com.hk/article/453171-%E3%80%8Cpinme%E3%80%8D%E8%87%AA%E5%8A%A9%E9%BB%9E%E9%A4%90%E7%B3%BB%E7%B5%B1%E7%99%BB%E5%A0%B4-%E9%A0%90%E8%A8%8860%E9%96%93%E9%A4%90%E5%BB%B3%E6%8E%A1%E7%94%A8'
    }
  ]

  get isH5() {
    return AppModule.isH5
  }
}
