





































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'
export type PlayBtnSize = 'large' | 'small'

@Component
export default class PlayBtn extends Vue {
  @Prop({ 
    type: String,
    default: 'large'
  })
  size: PlayBtnSize
}
