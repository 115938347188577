







































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { AppModule } from '@/store/module'
declare global {
  interface Window {
    onYouTubeIframeAPIReady(): void;
    YT: Youtube;
  }
}
interface Youtube {
  Player: PlayerConstructor;
  PlayerState: {};
}
interface PlayerConstructor {
  new (player: string | Element, options?: PlayerOptions): Player;
}
type ListType = 'playlist' | 'search' | 'user_uploads'
interface PlayListOptions {
  list: string | string[];
  listType?: ListType;
  index?: number;
  startSeconds?: number;
}
interface Player {
  stopVideo(): void;
  playVideo(): void;
  pauseVideo(): void;
  seekTo(seconds: number): void;
  loadPlayList(options: PlayListOptions): void;
}
interface PlayerOptions {
  width?: string;
  height?: string;
  videoId?: string;
  host?: string;
  playerVars?: {};
  events?: {};
}

@Component
export default class VideoPlayer extends Vue {
  @Prop({
    type: Boolean,
    default: false
  })
  is_show: boolean

  player = null as Player

  get curHost() {
    return window.location.origin
  }

  mounted() {
    const w = window as Window
    w.onYouTubeIframeAPIReady = () => {
      const playerEl = this.$refs.player as Element
      const YT = window.YT
      this.player = new YT.Player(playerEl, {
        events: {
          'onStateChange': this.onPlayerStateChange
        }
      })
    }
  }

  @Watch('is_show', {
    immediate: true
  })
  onIsShow() {
    if (this.is_show) {
      this.player && this.player.playVideo()
    } else {
      this.player && this.player.stopVideo()
    }
  }

  onPlayerStateChange(event) {
    // console.log('state change', event.data, window.YT.PlayerState)
  }
}
